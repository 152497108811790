table {
  text-align: left;
  margin: 0 auto;
  background-color: #c8c8c8ec;
  padding: 0.5rem;
  max-height: 60vh;
  overflow-y: scroll;
  display: block;

  .faded {
    opacity: 0;
  }
}

@media (max-width: 480px) {
  table {
    margin: auto;
  }
}

.row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}

* {
  touch-action: manipulation;
}

.pin {
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.faded {
  opacity:0;
}



th {
  padding: 0.125rem;
}

div {
  box-sizing: border-box;
}

.player-name {
  cursor: pointer;
}

.react-select__menu {
  background-color: whitesmoke;
  width: 100%;
  font-size: large;
}

details {
  font-size: large;
}

.react-select__placeholder {
  text-decoration-line: underline;
  text-decoration-style: dashed;
  font-size: large;
}

.react-select__input-container {
  font-size: large;
}

.loadout__panel {
  background-color: cadetblue;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
